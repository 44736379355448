<template>
    <div class="Packery" ref="container">
        <slot></slot>
    </div>
</template>

<script>
    import Packery from 'packery'

    export default {
        props: {
            sync: {
                type: Array,
                required: true,
                default: () => ([])
            },
        },

        data: () => ({
            packery: null,
        }),

        watch: {
            sync: {
                handler(newVal, oldVal) {
                    console.log('packery items changed');
                    console.log(newVal);
                    console.log(oldVal);
                },
                deep: true,
            },
        },

        mounted() {
            this.packery = new Packery(this.$refs.container);

            this.$Lazyload.$on('loaded', () => {
                this.packery.shiftLayout();
            });
        },

        beforeDestroy() {
            this.$Lazyload.$off('loaded');
        }
    }
</script>

<style lang="scss" scoped>
    .Packery {

    }
</style>