export const sort_by = (property_name) => {
    return (a,b) => (a[property_name] < b[property_name]) ? -1 : (a[property_name] > b[property_name]) ? 1 : 0;
}

export const convert_stringed_bool_to_bool = (string) => {
    if (!string) {
        return false;
    }

    if (string.toLowerCase() === 'true') {
        return true;
    }
    
    return false;
}

export const set_page_title = (page_title) => {
    const page_title_suffix = window.school_name ? ` - ${window.school_name}` : '';

    document.title = page_title + page_title_suffix;
}