<template>
    <span 
        v-if="$slots.default && $slots.default[0]" 
        :style="{
            fontSize: icon_font_size,
        }"
        :class="$slots.default[0].text" 
        class="Icon"
        aria-hidden="true"
    ></span>
</template>

<script>
    export default {
        props: {
            size: {
                type: [String, Number],
                required: false,
                default: 'inherit'
            },
        },

        computed: {
            icon_font_size() {
                if (isNaN(this.size)) {
                    return this.size;
                }

                return `${this.size}px`;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .Icon {
        line-height: 1;
        
        &::before {
            margin: 0;
        }
    }
</style>