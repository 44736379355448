const get = (url = '', options = {}, callback = (data) => { return data }) => {
    return new Promise((resolve, reject) => {
        Object.keys(options).forEach((key, index) => {
            url += index === 0 ? '?' : '&';
    
            url += `${key}=${options[key]}`;
        }); 
    
        fetch(url)
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    console.warn(response.error);
                    reject(response.error);
                    return;
                }

                if (response.data) {
                    resolve(callback(response.data));
                    return;
                }

                resolve(callback(response));
            })
            .catch(response => {
                if (response.error) {
                    reject(response.error);
                    return;
                }

                reject(response);
            });
    })
}

const post = () => {

}

const get_filters = () => {
    return get('/services/galleries.ashx/filters');
}

const get_gallery = (gallery_id) => {
    if (!gallery_id) {
        return new Promise(resolve => resolve({}));
    }

    const request_options = {
        id: gallery_id,
    };

    const callback = (data) => {
        data.images = data.images.map((image, index) => {
            image.index = index;

            return image;
        });

        return data;
    };

    return get('/services/galleries.ashx/gallery', request_options, callback);
}

const get_galleries = ({ keyword = '', sport_id = 0, season_id = 0, school_id = 0, page_size = 20, page = 1} = {}) => {
    const request_options = {
        keyword,
        sport_id,
        season_id,
        school_id,
        page_size,
        page,
    };

    return get('/services/galleries.ashx/galleries', request_options);
}

const get_component_data = (type = '', options = {}) => {
    if (!type) {
        return new Promise((resolve, reject) => {
            console.warn('Missing component type');
            reject('Missing component type');
        })
    }

    const request_options = {
        type,
        sport_id: 0,
        start: 0,
        ...options,
    };

    return get('/services/adaptive_components.ashx', request_options)
}

export default {
    get,
    post,
    get_gallery,
    get_galleries,
    get_filters,
    get_component_data,
}