<template>
    <div class="IconWrapper relative">
        <icon 
            :size="size"
            :style="{
                left: direction === 'left' ? `${offset}px` : 'auto',
                right: direction === 'right' ? `${offset}px` : 'auto',
            }"
            class="IconWrapper__icon"
        >{{ icon }}</icon>

        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                required: true,
                default: '',
            },

            size: {
                type: [String, Number],
                required: false,
                default: '1rem'
            },

            direction: {
                type: String,
                required: false,
                default: 'right',
                validator: (v) => ['right', 'left'].indexOf(v) !== -1
            },

            offset: {
                type: Number,
                required: false,
                default: 12,
            },
        },
    }
</script>

<style lang="scss" scoped>
    .IconWrapper {
        &__icon {
            pointer-events: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
</style>