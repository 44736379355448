const callback_on_click = (event, node_to_match, binding) => {
    const callback = binding.value;

    if (callback && typeof callback === 'function') {
        var prevented = false;

        for (let i = 0; i < event.path.length; i++) {
            let node = event.path[i];
    
            if (node.nodeType === 1 && node == node_to_match) {
                prevented = true;
            }
        }
    
        if (!prevented) {
            callback();
        }
    }    
};


export default {
    inserted(el, binding) {
        window.addEventListener('click', (event) => {
            callback_on_click(event, el, binding)
        });
    },

    unbind(el) {
        window.removeEventListener('click', callback_on_click, false);
    },
}