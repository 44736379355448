<template>
    <div v-prefix class="#ad">
        <component v-if="location && location.type" :is="location.type" :name="name" :location="location" :campaigns="campaigns" />
    </div>
</template>

<script>
    import api from '@/api'
    import SingleAd from './SingleAd.vue'
    import DfpAd from './DfpAd.vue'

    export default {
        props: {
            name: {
                type: String,
                required: true,
                default: '',
            },
        },

        components: {
            'single': SingleAd,
            'dfp': DfpAd,
        },

        data: () => ({
            location: null,

            campaigns: [],
        }),

        methods: {
            get_campaigns() {
                const request_options = {
                    type: 'ads',
                    sport_id: 0,
                    name: this.name
                };

                api.get('/services/adaptive_components.ashx', request_options)
                    .then(ad => {
                        this.location = ad.location;
                        this.campaigns = ad.campaigns;
                    })
            },
        },

        created() {
            this.get_campaigns();
        }
    }
</script>