import Vue from "vue";
import Vuex from "vuex";
import api from '@/api'
import { convert_stringed_bool_to_bool } from '@/helpers'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		dev_mode: process.env.NODE !== 'production',

		display_dummy_ads: false,

		template_options: {
			full_width: window.sidearm_gallery_options && window.sidearm_gallery_options.full_width ? convert_stringed_bool_to_bool(window.sidearm_gallery_options.full_width) : false,
			
			home_template: window.sidearm_gallery_options ? window.sidearm_gallery_options.homepage_template : 'template_1',
			
			gallery_template: window.sidearm_gallery_options ? window.sidearm_gallery_options.gallery_template : 'template_1',

			image_templates: window.sidearm_gallery_options ? window.sidearm_gallery_options.image_template : 'template_1',
			
			image_download_enabled: window.sidearm_gallery_options ? window.sidearm_gallery_options.image_download_enabled : false,

			watermark_enabled: window.sidearm_gallery_options ? window.sidearm_gallery_options.watermark_enabled : false,

			watermark_text: window.sidearm_gallery_options ? window.sidearm_gallery_options.watermark_text : '',
		},

		filters: {
			sport_id: 0,

			season_id: 0,

			school_id: 0,

			keyword: '',
		},

		galleries_current_page: 1,

		galleries: [],

		gallery_count: 0,

		repeated_ad_data: null,

		sports: [],

		seasons: [],

		schools: [],

		sport_from_route: null,
	},

	mutations: {
		set_sports(state, payload) {
			state.sports = payload;
		},

		set_seasons(state, payload) {
			state.seasons = payload;
		},

		set_schools(state, payload) {
			state.schools = payload;
		},

		set_filters(state, payload) {
			state.filters = payload;
		},

		set_gallery_count(state, payload) {
			state.gallery_count = payload;
		},

		set_template_options(state, payload) {
			state.template_options = payload;
		},

		reset_galleries_current_page(state) {
			state.galleries_current_page = 1;
		},

		increment_galleries_current_page(state) {
			++state.galleries_current_page
		},

		decrement_galleries_current_page(state) {
			--state.galleries_current_page;
		},

		update_galleries(state, payload) {
			if (Array.isArray(payload)) {
				state.galleries = payload.slice();
			}
		},

		load_more_galleries(state, payload) {
			if (Array.isArray(payload)) {
				state.galleries = state.galleries.concat(payload);
			}
		},

		set_sport_from_route(state, payload) {
			state.sport_from_route = payload;
		},

		set_repeated_ad_data(state, payload) {
			state.repeated_ad_data = payload;
		},

		set_display_dummy_ads(state, payload) {
			state.display_dummy_ads = !!payload;
		}
	},

	actions: {
		set_sports({ commit }, sports) {
			commit('set_sports', sports);
		},

		set_sport_from_route({ commit, state }, route, sport) {
			const matched_sport = state.sports.find(sport => sport.id === parseInt(route.params.sport_id));

			if (matched_sport) {
				commit('set_sport_from_route', matched_sport);
			}
			else {
				commit('set_sport_from_route', null);
			}
		},

		set_seasons({ commit }, seasons) {
			commit('set_seasons', seasons);
		},

		set_schools({ commit }, schools) {
			commit('set_schools', schools);
		},

		set_gallery_count({ commit }, gallery_count) {
			commit('set_gallery_count', gallery_count);
		},

		set_filters({ commit, state }, filters) {
			let new_filters = {};

			console.log(filters);

			Object.keys(filters).forEach(key => {
				if (state.filters.hasOwnProperty(key)) {
					new_filters[key] = filters[key];
				}
			});

			let updated_filters = {
				...state.filters,
				...new_filters
			};

			commit('set_filters', updated_filters);
		},

		set_template_options({ commit, state }, template_options) {
			let new_template_options = {};

			console.log(template_options);

			Object.keys(template_options).forEach(key => {
				if (state.template_options.hasOwnProperty(key)) {
					new_template_options[key] = template_options[key];
				}
			});

			let updated_template_options = {
				...state.template_options,
				...new_template_options
			};

			commit('set_template_options', updated_template_options);
		},

		fetch_new_galleries(context) {
			return new Promise((resolve, reject) => {
				api.get_galleries(context.state.filters)
					.then(galleries => {
						context.commit('reset_galleries_current_page');
						context.commit('update_galleries', []);
						context.commit('update_galleries', galleries);

						resolve();
					})
					.catch(() => {
						reject()
					});
			})
		},

		load_more_galleries(context, page_size, page) {
			return new Promise((resolve, reject) => {
				context.commit('increment_galleries_current_page');

				const request_options = {
					...context.state.filters,
					page: context.state.galleries_current_page,
				};

				api.get_galleries(request_options)
					.then(galleries => {
						if (galleries) {
							context.commit('load_more_galleries', galleries);
						}
						else {
							context.commit('decrement_galleries_current_page');
						}

						resolve();
					})
					.catch(error => {
						reject();
					});
			});

		},

		add_galleries({ commit }) {
			
		},

		load_repeated_ad_data({commit}) {
			api.get_component_data('ads', { name: 'galleries-repeated-1'})
				.then(ad => {
					if (ad) {
						commit('set_repeated_ad_data', ad);
					}
				});
		},

		set_display_dummy_ads({commit}, value) {
			commit('set_display_dummy_ads', value);
		}
	},

	modules: {

	}
});
