import Vue from "vue";
import { mapActions, mapState } from "vuex";
import VueLazyload from 'vue-lazyload'
import router from "./router";
import store from "./store";

import App from "./App.vue";
import AspectRatio from '@/components/AspectRatio.vue'
import Icon from '@/components/Icon.vue'
import IconWrapper from '@/components/IconWrapper.vue'
import Packery from '@/components/Packery.vue'
import AdSpot from '@/components/Ads/AdSpot.vue'

import api from '@/api'
import prefix_directive from '@/directives/prefix'
import clicked_outside_directive from '@/directives/clicked_outside'
import moment_mixin from '@/mixins/moment'

Vue.config.productionTip = false;

Vue.use(VueLazyload, {
	attempt: 1,
	observer: true,
});

Vue.component('aspect-ratio', AspectRatio);
Vue.component('icon', Icon);
Vue.component('icon-wrapper', IconWrapper);
Vue.component('packery', Packery);
Vue.component('ad-spot', AdSpot);

Vue.directive('prefix', prefix_directive);
Vue.directive('clickedOutside', clicked_outside_directive);
Vue.mixin(moment_mixin);

if (process.env.NODE_ENV !== 'production') {
	const style = document.createElement('style');
	style.innerHTML = `
		:root {
			--primary-background: 	#1C453B;
			--primary-text: 		#FFFFFF;
			--secondary-background: #D9D9DB;
			--secondary-text: 		#1C453B;
			--safe-text-on-white: 	#000000;
			--safe-text-on-black: 	#FFFFff;
		}
	`;
	document.head.appendChild(style);
}

new Vue({
	router,
	store,
	render: h => h(App),

	data: () => ({

	}),

	computed: {
		...mapState([
			'template_options'
		]),
	},

	methods: {
		...mapActions([
			'set_sports',
			'set_seasons',
			'set_schools',
			'set_gallery_count',
		]),
	},

	created() {
		api.get_filters()
			.then(filters => {
				this.set_sports(filters.sports);
				this.set_seasons(filters.seasons);
				this.set_schools(filters.schools);
				this.set_gallery_count(filters.total_galleries);
			})
			.catch(error => { });
	},

	mounted() {
		if (process.env.NODE_ENV === 'production') {
			document.addEventListener('contextmenu', (e) => { e.preventDefault() }, false);
		}
	}
}).$mount("#app");
