<template>
    <div v-if="campaign" class="single-ad">
        <a 
            :href="campaign.link" 
            :target="campaign.open_in_new_window ? '_blank' : ''"
            :aria-label="alt_text"
        >
            <img 
                :src="image.image" 
                :alt="alt_text"
            />
        </a>

        <!--
        <a data-bind="attr: { 'aria-label': ((typeof picture.images[0] != 'undefined') ? (picture.images[0].alt) : (title)), href: link, title: title, target: open_in_new_window ? '_blank' : '' }">
            <picture data-bind="html5Picture: picture, alt: ((typeof picture.images[0] != 'undefined') ? (picture.images[0].alt) : (title))"></picture>
        </a>
        -->
    </div>
</template>

<script>
    export default {
        props: {
            location: {
                type: Object,
                required: true,
            },

            campaigns: {
                type: Array,
                required: true,
                default: () => ([]),
            },
        },

        data: () => ({

        }),

        computed: {
            campaign() {
                if (this.campaigns && this.campaigns.length) {
                    return this.campaigns[0];
                }

                return null;
            },

            image() {
                if (typeof this.campaign.picture.images[0] != 'undefined') {
                    return this.campaign.picture.images[0];
                }
                return null;
            },

            alt_text() {
                if(this.image) {
                    return this.image.alt;
                }

                return campaign.title;
            },
        }
    }
</script>