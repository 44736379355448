<template>
    <div class="aspect-ratio flex minh-100 minw-100">
        <div class="aspect-ratio__sizer" :style="{ paddingBottom: aspect_ratio_percentage }"></div>

        <div class="aspect-ratio__content">
            <slot></slot>
        </div>
    </div>    
</template>

<script>
    export default {
        props: {
            ratio: {
                type: [Number, String],
                required: true,
                default: 1,
            },
        },

        computed: {
            aspect_ratio_percentage() {
                if (typeof this.ratio === 'string' && this.ratio.includes('%')) {
                    return this.ratio;
                }

                return `${this.ratio * 100}%`
            }
        },
    }
</script>

<style lang="scss" scoped>
    .aspect-ratio {
        &__sizer {
            content: '';
            display: block;
            flex: 0 0 0px;
            width: 0px;
            height: 0px;
        }

        &__content {
            flex: 1 1 auto;
        }
    }
</style>