<template>
	<div>
		<main-header />

		<main v-prefix class="#main px-2">
			<transition name="fade">
				<router-view />
			</transition>
		</main>

		<footer>
            <div class="pb-2 py-4 text-center">
                <a href="https://www.sidearmsports.com/privacypolicy/" target="_blank" class="mx-1">Privacy Policy</a>
                <a href="https://www.sidearmsports.com/terms-of-service/" target="_blank" class="mx-1">Terms of Service</a>
            </div>
        </footer>

		<div v-prefix :class="{ 'is-hidden': sticky_footer_is_hidden }" class="#sticky-footer text-center w-100" ref="sticky">
			<button v-prefix v-show="sticky_footer_close_button_open" @click="close_sticky_footer" type="button" class="#sticky-footer__close sidearm-galleries-btn --primary">
				<icon>sf-close</icon>
			</button>

			<ad-spot name="galleries-sticky-footer-1" />
		</div>
	</div>
</template>

<script>
	import MainHeader from '@/components/MainHeader'

	import api from '@/api'
	import analytics_mixin from '@/mixins/analytics'
	import { mapState, mapActions } from 'vuex'

	export default {
		components: {
			MainHeader,
		},

		mixins: [
			analytics_mixin
		],

		data: () => ({
			sticky_footer_close_button_open: false,

			sticky_footer_is_hidden: false,
		}),

		computed: {
			...mapState([
				'template_options',
			]),
		},

		watch: {
			$route(to, from) {
				this.update_templates_by_query_params();

				this.redirect_from_old_url_structure();
				this.style_dummy_ads();

				if (to.name !== from.name) {
					let classes = document.querySelector('html').classList;

					classes.forEach(className => {
						if (className.indexOf('active-route-') === 0) {
							classes.remove(className);
						}
					});

					classes.add(`active-route-${to.name}`);
				}

				this.ga_set_page(this.$route.fullPath);
				this.ga_track_page_view(this.$route.fullPath);
			}
		},

		methods: {
			...mapActions([
				'set_template_options',
				'load_repeated_ad_data',
				'set_display_dummy_ads',
			]),

			close_sticky_footer() {
				this.$refs.sticky.style.display = 'none';
			},

			hide_sticky_footer_on_scroll() {
				this.$nextTick(() => {
					if (window.pageYOffset > 100) {
						this.sticky_footer_is_hidden = true;
					}
					else {
						this.sticky_footer_is_hidden = false;
					}
				});
			},

			update_templates_by_query_params() {
				const params = this.$route.query;
				const options = {};

				if (params.home) {
					options.home_template = params.home;
				}
				if (params.gal) {
					options.gallery_template = params.gal;
				}
				if (params.fluid) {
					options.full_width = params.fluid;
				}

				if (Object.keys(options).length) {
					this.set_template_options(options);
				}
			},

			redirect_from_old_url_structure() {
				const params = this.$route.query;

				if (params.gallery) {
					api.get_gallery(params.gallery)
						.then(gallery => {
							this.$router.replace({ 
								name: 'gallery', 
								params: { 
									sport_slug: gallery.sport.slug,
									gallery_slug: gallery.title_slug.substring(0,30),
									gallery_id: gallery.id,
								}
							});
						});
				}
				else if (params.path) {
					api.get('/services/sportnames.aspx?format=json')
						.then(response => {
							const sports = response.sports;
							const matched_sport = sports.find(sport => sport.sportInfo.sport_shortname === params.path);

							if (matched_sport) {
								this.$router.replace({
									name: 'sport_home',
									params: {
										sport_slug: matched_sport.sportInfo.sport_abbrev,
										sport_id: matched_sport.sport,
									}
								})
							}
						})
				}
			},

			style_dummy_ads() {
				const params = this.$route.query;

				if (params['display-ads']) {
					this.set_display_dummy_ads(true);
					document.querySelector('body').classList.add('dummy-ads-active');
				}
			}
		},

		created() {
			this.load_repeated_ad_data();
		},

		mounted() {
			this.update_templates_by_query_params();

			this.style_dummy_ads();

			this.redirect_from_old_url_structure();

			const sticky_ad_check_interval = setInterval(() => {
				if (this.$refs.sticky.querySelector('iframe')) {
					clearInterval(sticky_ad_check_interval);

					setTimeout(() => {
						this.sticky_footer_close_button_open = true;
						window.addEventListener('scroll', this.hide_sticky_footer_on_scroll);
					}, 5000);				
				}
			}, 100);
		},
	}
</script>

<style lang="scss">
	@import '@/scss/app';

	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}

	.fade-enter, .fade-leave-to {
		opacity: 0;
	}

	body.dummy-ads-active {
		.sidearm-galleries__main-header {
			.sidearm-galleries__ad {
				width: 150px;
				height: 50px;
				background-color: lime;
			}
		}
		
		.sidearm-galleries__sticky-footer,
		.sidearm-galleries__home1,
		.sidearm-galleries__home2,
		.sidearm-galleries__home3,
		.sidearm-galleries__gallery1,
		.sidearm-galleries__gallery2,
		.sidearm-galleries__gallery3,
		.sidearm-galleries__gallery4,
		.sidearm-galleries__gallery5,
		.sidearm-galleries__gallery6{
			.sidearm-galleries__ad, .dfp-ad {
				background-color: lime;
				height: 90px;
				margin: 0 auto;
				width: 728px;
			}
		}

		.sidearm-galleries__image {
			.sidearm-galleries__ad, .dfp-ad {
				background-color: lime;
				height: 250px;
				margin: 0 auto;
				width: 300px;
			}
		}
	}

	@media (max-width: 767px) {
		body.dummy-ads-active {
			.sidearm-galleries__home1,
			.sidearm-galleries__home2,
			.sidearm-galleries__home3,
			.sidearm-galleries__gallery1,
			.sidearm-galleries__gallery2,
			.sidearm-galleries__gallery3,
			.sidearm-galleries__gallery4,
			.sidearm-galleries__gallery5,
			.sidearm-galleries__gallery6{
				.sidearm-galleries__ad, .dfp-ad {
					height: 250px;
					width: 300px;
				}
			}

			.sidearm-galleries__sticky-footer {
				.sidearm-galleries__ad, .dfp-ad {
					height: 50px;
					width: 320px;
				}
			}
		}
	}
</style>

<style lang="scss" scoped>
	.#{$prefix} {
		&__main { }

		&__sticky-footer {
			background-color: rgba($color-black, 0.8);
			bottom: 0;
			left: 0;
			position: fixed;
			transition: .35s;
			z-index: 99;

			&.is-hidden {
				transform: translateY(200%);
			}
		}

		&__sticky-footer__close {
			position: absolute;
			right: 0;
			bottom: 100%;
		}

		@media (max-width: #{$breakpoint-medium-down}) {
			&__main {
				padding: 0 $spacer !important;
			}
		}
	}
</style>