export default {
    bind(el) {
        let classes = el.classList;

        classes.forEach(className => {
            if (className.indexOf('#') === 0) {
                classes.add(`sidearm-galleries__${className.slice(1, className.length)}`);
                classes.remove(className);
            }
        });
    },

    update(el) {
        let classes = el.classList;

        classes.forEach(className => {
            if (className.indexOf('#') === 0) {
                classes.add(`sidearm-galleries__${className.slice(1, className.length)}`);
                classes.remove(className);
            }
        });
    }
}