import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/Home/Home.vue'),
	},

	{
		path: '/:sport_slug/:sport_id',
		name: 'sport_home',
		component: () => import('@/views/Home/Home.vue'),
	},

	{
		path: '/:sport_slug/:gallery_slug/:gallery_id',
		name: 'gallery',
		component: () => import('@/views/Gallery/Gallery.vue'),

	},

	{
		path: "/:sport_slug/:gallery_slug/:image_caption_slug/:gallery_id/:image_id",
		name: 'image',
		component: () => import('@/views/Image/Image.vue'),

	},

	{
		path: '*',
		redirect: { name: 'home' },
	},
];

const router = new VueRouter({
	mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
	
	base: process.env.NODE_ENV === 'production' ? '/galleries/' : '/',

	routes,

	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;
