var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#main-header-menu-sports w-100 bg-black pa-4",class:{ 
        'is-toggled': _vm.value,
        'full-width': _vm.template_options.full_width,
    }},[_c('ul',[_c('li',{staticClass:"border-dark-y"},[_c('router-link',{attrs:{"to":{ name: 'home' },"tabindex":_vm.value ? 0 : -1}},[_vm._v("All Sports")])],1),_vm._l((_vm.sports),function(sport){return _c('li',{key:sport.id,staticClass:"border-dark-y"},[_c('router-link',{attrs:{"to":{ 
                    name: 'sport_home', 
                    params: {
                        sport_slug: sport.slug,
                        sport_id: sport.id,
                    } 
                },"tabindex":_vm.value ? 0 : -1}},[_vm._v(_vm._s(sport.title))])],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }