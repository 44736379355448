export default {
    methods: {
        ga_get_trackers() {
            if ('ga' in window) {
                if (typeof window.value === 'undefined') { 
                    window.value = 1;
                }
                const trackers = window.ga.getAll();

                return trackers;
            } else {
                return null;
            }
        },
    
        ga_track_event (category, action, label, value) {
            const trackers = this.ga_get_trackers();

            if (trackers){
                trackers.forEach(tracker => {
                    tracker.send({
                        hitType: 'event',
                        eventCategory: category,
                        eventAction: action,
                        eventLabel: label,
                        eventValue: value
                    });
                });
            }
        },

        ga_set_page(location) {
            const trackers = this.ga_get_trackers();

            if (trackers) {
                trackers.forEach(tracker => {
                    tracker.set({
                        page: location
                    });
                });
            }
        },

        ga_track_page_view(location) {
            const trackers = this.ga_get_trackers();

            if (trackers) {
                trackers.forEach(tracker => {
                    tracker.send({
                        hitType: 'pageview',
                        page: location
                    });
                });
            }
        }
    }
}