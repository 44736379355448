<template>
    <header v-prefix :class="{ 'pr-2': !template_options.full_width }" class="#main-header bg-white w-100 pl-2" ref="main_header">
        <div :class="{ 'full-width': template_options.full_width }" class="row flex flex-justify-between h-100 relative">
            <div class="flex-item-auto flex flex-align-center">
                <a href="/" class="text-decoration-none">
                    <img src="/images/logos/site/site.png?height=60" alt="Logo - Go to galleries home" class="mr-1" />
                </a>
                
                <router-link :to="{ name: 'home' }" class="flex flex-align-center text-decoration-none">
                    <span class="type-title hide-on-small-down">
                        <span v-if="sport_from_route" class="hide-on-large-down">{{ sport_from_route.title }} </span>
                        <span v-if="sport_from_route" class="hide-on-x-large">{{ sport_from_route.abbreviation }} </span>
                        <span>Photos</span>
                    </span>
                </router-link>
            </div>

            <div class="flex flex-align-center flex-shrink-1 flex-item-auto">
                <ad-spot name="galleries-cs-header-1" class="mx-1" />
            </div>

            <div v-prefix class="#main-header__right flex flex-justify-end flex-item-1 ml-auto bg-white">
                <button v-prefix @click="toggle_sports_menu" :class="{ 'is-toggled': sports_menu_open }" type="button" aria-label="Toggle sport menu" class="#main-header__menu-toggle border-light-l flex-item-auto" ref="menu_toggle">
                    <icon v-show="!sports_menu_open">sf-menu</icon>
                    <icon v-show="sports_menu_open">sf-close-minimal</icon>
                </button>

                <main-header-menu-sports v-model="sports_menu_open" />

                <icon-wrapper 
                    v-prefix 
                    :offset="30" 
                    :class="{ 
                        'is-toggled': search_is_focused,
                        'border-light-r': !template_options.full_width,
                    }" 
                    icon="sf-search-2" 
                    size="24px" 
                    class="#main-header__search-container border-light-l overflow-hidden hide-on-medium-down"
                >
                    <input 
                        v-prefix 
                        v-model="search_keyword"
                        @focus="set_search_input_focus(true)" 
                        @blur="set_search_input_focus(false)" 
                        @keypress.enter="search_galleries"
                        :placeholder="search_is_focused ? 'Search galleries' : ''" 
                        type="text" 
                        class="#main-header__search-input h-100 w-100" 
                        aria-label="Search galleries" 
                        ref="search_input" 
                    />
                </icon-wrapper>

                <div class="hide-on-large">
                    <button 
                        v-prefix 
                        @click="toggle_mobile_search" 
                        :class="{
                            'is-active': mobile_search_open,
                        }"
                        type="button" 
                        class="#main-header__search-toggle border-light-l"
                    >
                        <icon>sf-search-2</icon>
                    </button>

                    <input 
                        v-prefix 
                        v-model="search_keyword"
                        @keypress.enter="search_galleries"
                        :class="{
                            'is-active': mobile_search_open,
                        }"
                        placeholder="Search galleries" 
                        type="text" 
                        class="#main-header__search-input-mobile sidearm-galleries-input" 
                        aria-label="Search galleries" 
                        ref="search_input" 
                    />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import MainHeaderMenuSports from '@/components/MainHeaderMenuSports.vue'

    import { mapState, mapActions } from 'vuex'
    import debounce from 'lodash-es/debounce'

    export default {
        components: {
            MainHeaderMenuSports,
        },

        data: () => ({
            filter_menu_open: false,

            sports_menu_open: false,

            search_is_focused: false,

            search_keyword: '',

            prevent_search: false,

            mobile_search_open: false,
        }),

        computed: {
            ...mapState([
                'template_options',
                'sports',
                'sport_from_route',
            ]),
        },

        watch: {
            $route(to, from) {
                this.sports_menu_open = false;
                this.filter_menu_open = false;

                this.set_sport_from_route(to);
            },

            search_keyword(newVal) {
                if (!this.prevent_search && newVal.length >= 3) {
                    this.search_galleries();
                }
            }
        },

        methods: {
            ...mapActions([
                'set_filters',
                'set_sport_from_route',
            ]),

            toggle_sports_menu() {
                this.filter_menu_open = false;
                this.sports_menu_open = !this.sports_menu_open;
            },

            toggle_filter_menu() {
                this.sports_menu_open = false;
                this.filter_menu_open = !this.filter_menu_open;
            },

            set_search_input_focus(is_focused = true) {
                this.search_is_focused = is_focused;
            },

            toggle_mobile_search() {
                this.mobile_search_open = !this.mobile_search_open;
            },

            search_galleries: debounce(function() {
                this.set_filters({keyword: this.search_keyword});

                if (!['home', 'sport_home'].includes(this.$route.name)) {
                    this.$router.push({ name: 'home' });
                }
            }, 600),
        },

        mounted() {
            this.$refs.main_header.addEventListener('keyup', (event) => {
                if (event.keyCode === 27) {
                    event.preventDefault();
                    if (this.sports_menu_open) {
                        this.$refs.menu_toggle.focus();
                    }

                    if (this.search_is_focused) {
                        this.$refs.search_input.blur();
                    }

                    this.sports_menu_open = false;
                    this.search_is_focused = false;
                }
            });
        }
    }
</script>

<style lang="scss">
    .#{$prefix}__main-header {
        box-shadow: 0px 1px 12px rgba($color-black, 0.08);
        height: 84px;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 100;
        
        &__menu-toggle {
            @include base-reset();

            font-size: 24px;
            transition: .35s;
            width: 84px;

            &:hover, &.is-toggled, &:focus {
                background-color: $color-black;
                color: $color-white;                
            }

            &:last-of-type {
                margin-right: -1px;
            }
        }

        &__search-container {
            @include flex-item(0 0 84px);

            max-width: 300px;
            transition: .2s;

            &.is-toggled {
                @include flex-item(0 0 100%);

            }
        }

        &__search-input {
            @include base-reset();

            text-indent: 30px;
            padding-right: 72px;
        }

        &__search-toggle {
            @include base-reset();

            font-size: 24px;
            height: 60px;
            transition: .35s;
            width: 60px;

            &:hover, &.is-toggled, &:focus {
                background-color: $color-black;
                color: $color-white;                
            }
        }

        &__search-input-mobile {
            @include base-reset();

            border: none !important;
            height: 60px !important;
            opacity: 0;
            position: absolute;
            right: 60px;
            top: 0;
            transform: translateX(-100%);
            transition: opacity .35s;
            width: calc(100% - 60px + #{$spacer}) !important;

            &:focus, &.is-active {
                opacity: 1;
                transform: translateX(0);
            }
        }

        @media (max-width: #{$breakpoint-medium-down}) {
            height: 60px;
            padding-left: $spacer !important;
            padding-right: 0 !important;

            &__right {
                flex-shrink: 0 !important;
                flex-basis: auto !important;
            }

            &__menu-toggle {
                width: 60px;
            }

            &__search-container {
                flex: 0 0 60px;
            }

            &__search-input {
                padding-right: 48px;
            }
        }
    }
</style>

<style lang="scss">
    .#{$prefix}__main-header {
        @media (max-width: #{$breakpoint-medium-down}) {
            &__search-container {
                .IconWrapper__icon {
                    right: 18px;
                }
            }
        }
    }
</style>