<template>
    <nav 
        v-prefix 
        :class="{ 
            'is-toggled': value,
            'full-width': template_options.full_width,
        }" 
        class="#main-header-menu-sports w-100 bg-black pa-4"
    >
        <ul>
            <li class="border-dark-y">
                <router-link :to="{ name: 'home' }" :tabindex="value ? 0 : -1">All Sports</router-link>
            </li>

            <li v-for="sport in sports" :key="sport.id" class="border-dark-y">
                <router-link 
                    :to="{ 
                        name: 'sport_home', 
                        params: {
                            sport_slug: sport.slug,
                            sport_id: sport.id,
                        } 
                    }" 
                    :tabindex="value ? 0 : -1"
                >{{ sport.title }}</router-link>
            </li>
        </ul>
    </nav>    
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        props: {
            value: {
                type: Boolean,
                required: true,
                default: false,
            },
        },

        computed: {
            ...mapState([
                'sports',
                'template_options',
            ]),
        },
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__main-header-menu-sports {
        @include hide();
        
        &.is-toggled {
            @include unhide();

            left: 0;
            max-height: calc(100vh - 84px - #{$spacer*2});
            position: absolute;
            top: 100%;
            overflow: auto;
            width: 100%;

            &.full-width {
                width: calc(100% + #{$spacer*2});
                left: $spacer*-2;
            }
        }

        ul {
            @include reset-list();
            column-count: 4;
            column-gap: $spacer*2;
        }

        li {
            margin-bottom: -1px;
            break-inside: avoid;
            break-inside: avoid-column;
        }

        a {
            color: $color-white;
            display: block;
            padding: $spacer $spacer*2;
            text-decoration: none;
            transition: .35s;

            &:hover {
                background-color: $color-primary;
                color: $text-on-primary;
            }
        }

        @media (max-width: $breakpoint-large-down) {
            ul {
                column-count: 3;
            }
        }

        @media (max-width: $breakpoint-medium-down) {
            ul {
                column-count: 2;
            }
        }

        @media (max-width: $breakpoint-small-down) {
            ul {
                column-count: initial;
            }
        }
    }
</style>